<script setup lang="ts">
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

// Config
const props = defineProps({
  devise: {
    type: Object,
  },
  canSee: {
    type: Boolean,
    default: false,
  },
})

// State
const swiper: Ref<SwiperType | null> = ref(null)
const showVideo = ref(false)

// Computed
const activeIndex = computed(() => {
  if (swiper.value)
    return swiper.value.realIndex

  return null
})

// Methods
function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}
function next() {
  if (swiper.value)
    swiper.value.slideNext()
}
function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}
function goToSlide(index: any) {
  if (swiper.value)
    swiper.value.slideTo(index)
}
function goToLink(link: any) {
  if (link.enabled)
    window.location.href = link.value.href
}

onMounted(() => {
  if (
    props.devise.fields.wistiaId.enabled
    && props.devise.fields.videoMode.value === 'playAutomatically'
  ) {
    showVideo.value = true
  }
})
</script>

<template>
  <div class="relative stay-grid-gallery">
    <div class="relative grid-cols-4 w-full gap-2 hidden lg:grid">
      <div
        class="col-span-3 grid-rows-12 grid transform duration-500 delay-300"
        :class="{
          'col-start-2 row-start-1 items-end': devise.fields.bigImageOnRight.value,
          'group cursor-pointer': devise.fields.link1.enabled,
          'translate-y-12 opacity-0': !canSee,
          'opacity-100': canSee,
        }"
        @click="goToLink(devise.fields.link1)"
      >
        <div class="relative h-full row-span-9 overflow-hidden">
          <VoixMedia v-if="!showVideo" :field="devise.fields.image1" class="object-cover w-full h-full" :class="{ 'group-hover:scale-105 transform duration-300': devise.fields.link1.enabled }" />

          <div
            v-else
            class="w-full h-full overflow-hidden object-cover  flex justify-center items-center bg-black"
            :style="sliderStyles"
          >
            <glue-media-wistia
              :video-id="devise.fields.wistiaId.value"
              class="w-full aspect-video relative z-30"
            />
          </div>
          <div
            class="absolute bottom-0 bg-white h-12 w-96 grid-gallery-card"
            :class="{
              'left-0': !devise.fields.bigImageOnRight.value,
              'right-0': devise.fields.bigImageOnRight.value,
            }"
          />

          <div
            v-if="
              devise.fields.wistiaId
                && devise.fields.wistiaId.enabled
                && devise.fields.videoMode.value === 'clickToActivate'
                && !showVideo
            "
            class="absolute z-20 inset-0 m-8 text-zinc-100 flex justify-center items-center"
          >
            <button @click="showVideo = true">
              <svg
                width="100"
                height="100"
                viewBox="0 0 62 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="31"
                  cy="31"
                  r="30"
                  fill="black"
                  fill-opacity="0.4"
                  stroke="white"
                  stroke-width="1.5"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  transform="translate(24 21)"
                  d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="row-span-3"
          :class="{
            'flex justify-end h-full': devise.fields.bigImageOnRight.value,
          }"
        >
          <div class="bg-white grid-gallery-card h-full w-96 flex items-center">
            <div class="p-10 pt-0">
              <div class="headline-3 mb-2 grid-gallery-card-title">
                {{ devise.fields.image1Title.value }}
              </div>
              <div
                class="font-light text-[17px] text-zinc-600 grid-gallery-card-description"
                :class="{ ' line-clamp-4': devise.fields.clampDescriptions.value }"
              >
                {{ devise.fields.image1Description.value }}
              </div>
              <div>
                <a
                  v-if="devise.fields.link1.enabled"
                  :href="devise.fields.link1.value?.href"
                  :target="devise.fields.link1.value.target"
                  class="inline-block btn btn-xs btn-ghost mt-8"
                >
                  {{ devise.fields.link1.value.text }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-rows-12 grid">
        <div
          class="row-span-3 overflow-hidden transform duration-500 delay-500"
          :class="{
            'row-start-1 col-start-1': devise.fields.bigImageOnRight.value,
            'group cursor-pointer': devise.fields.link2.enabled,
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
          @click="goToLink(devise.fields.link2)"
        >
          <VoixMedia :field="devise.fields.image2" class="object-cover w-full h-full" :class="{ 'group-hover:scale-105 transform duration-300': devise.fields.link2.enabled }" />
        </div>
        <div
          class="row-span-3 bg-white grid-gallery-card p-10 flex flex-col justify-center transform duration-500 delay-500"
          :class="{
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
        >
          <div class="headline-3 mb-2 grid-gallery-card-title">
            {{ devise.fields.image2Title.value }}
          </div>
          <div
            class="font-light text-[17px] text-zinc-600 grid-gallery-card-description"
            :class="{ 'line-clamp-3': devise.fields.clampDescriptions.value }"
          >
            {{ devise.fields.image2Description.value }}
          </div>
          <div>
            <a
              v-if="devise.fields.link2.enabled"
              :href="devise.fields.link2.value?.href"
              :target="devise.fields.link2.value.target"
              class="inline-block btn btn-xs btn-ghost mt-8"
            >
              {{ devise.fields.link2.value.text }}
            </a>
          </div>
        </div>
        <div
          class="row-span-3 overflow-hidden transform duration-500 delay-700"
          :class="{
            'group cursor-pointer': devise.fields.link3.enabled,
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
          @click="goToLink(devise.fields.link3)"
        >
          <VoixMedia :field="devise.fields.image3" class="object-cover w-full h-full" :class="{ 'group-hover:scale-105 transform duration-300': devise.fields.link3.enabled }" />
        </div>
        <div
          class="row-span-3 bg-white grid-gallery-card p-10 flex flex-col justify-center transform duration-500 delay-70"
          :class="{
            'translate-y-12 opacity-0': !canSee,
            'opacity-100': canSee,
          }"
        >
          <div class="headline-3 mb-2 grid-gallery-card-title">
            {{ devise.fields.image3Title.value }}
          </div>
          <div
            class="font-light text-[17px] text-zinc-600 grid-gallery-card-description"
            :class="{ 'line-clamp-3': devise.fields.clampDescriptions.value }"
          >
            {{ devise.fields.image3Description.value }}
          </div>
          <div>
            <a
              v-if="devise.fields.link3.enabled"
              :href="devise.fields.link3.value?.href"
              :target="devise.fields.link3.value.target"
              class="inline-block btn btn-xs btn-ghost mt-8"
            >
              {{ devise.fields.link3.value.text }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="block lg:hidden pl-8">
      <Swiper
        slides-per-view="auto"
        :space-between="10"
        :centered-slides="false"
        :loop="true"
        class="flex items-stretch"
        style="height: 500px"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="(s, key) in 3"
          :key="key"
          class="flex items-stretch"
          style="width: 300px"
        >
          <div class="flex justify-center h-full" style="width: 300px">
            <div class="bg-white h-full" @click="goToLink(devise.fields[`link${s}`])">
              <div class="aspect-video">
                <VoixMedia :field="devise.fields[`image${s}`]" class="w-full h-full object-cover" />
              </div>
              <div class="bg-white p-10">
                <div class="headline-3 mb-2">
                  {{ devise.fields[`image${s}Title`].value }}
                </div>
                <div class="body text-zinc-600 line-clamp-4">
                  {{ devise.fields[`image${s}Description`].value }}
                </div>
                <div>
                  <a
                    v-if="devise.fields[`link${s}`].enabled"
                    :href="devise.fields[`link${s}`].value?.href"
                    :target="devise.fields[`link${s}`].value.target"
                    class="inline-block btn btn-xs btn-ghost mt-4"
                  >
                    {{ devise.fields[`link${s}`].value.text }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <!-- Slider Arrows -->
      <div
        class="absolute inset-2 z-10 pointer-events-none flex justify-between items-center"
      >
        <button
          class="pointer-events-auto"
          @click="prev"
        >
          <div
            class="slider-button text-orange-900 hover:text-white bg-orange-50"
          >
            <svg
              class="w-7 h-7"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </button>
        <button
          class="pointer-events-auto"
          @click="next"
        >
          <div
            class="slider-button text-orange-900 hover:text-white bg-orange-50"
          >
            <svg
              class="w-7 h-7 transform rotate-180"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </button>
      </div>

      <!-- Slider Pagination -->
      <div class="flex z-10 justify-center px-4 mt-8">
        <div class="flex space-x-3">
          <button
            v-for="(slide, s) in 3"
            :key="s"
            class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
            :class="{
              'bg-gray-500': s !== activeIndex % 3,
              'bg-white': s === activeIndex % 3,
            }"
            @click="goToSlide(s)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
